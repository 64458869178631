@tailwind base;
@tailwind components;
@tailwind utilities;


@layer fonts {
    @font-face {
        font-family: 'pluto';
        font-style: normal;
        font-weight: 400;
        src: url('/public/assets/fonts/pluto.ttf'); 
        font-display: swap;
        src: local(''),
             url('/public/assets/fonts/pluto.ttf') format('truetype'),
      }
      @font-face {
        font-family: 'coffee-rg';
        font-style: normal;
        font-weight: 400;
        src: url('/public/assets/fonts/coffee-rg.ttf'); 
        font-display: swap;
        src: local(''),
             url('/public/assets/fonts/coffee-rg.ttf') format('truetype'),
      }
      @font-face {
        font-family: 'coffee-bd';
        font-style: normal;
        font-weight: 400;
        src: url('/public/assets/fonts/coffee-bd.ttf'); 
        font-display: swap;
        src: local(''),
             url('/public/assets/fonts/coffee-bd.ttf') format('truetype'),
      }
@font-face {
    font-family: 'work';
    font-style: normal;
    font-weight: 400;
    src: url('/public/assets/fonts/work-sans-v16-latin-regular.eot'); 
    font-display: swap;
    src: local(''),
         url('/public/assets/fonts/work.ttf') format('truetype'),
  }}